<script>
export default {
  beforeCreate() {
    window.location.href = 'https://primowebbrowser.com';
  }
};
</script>

<template>
  <div>

  </div>
</template>
